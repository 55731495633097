import { Avatar, Button, MenuLayout } from '@orus.eu/pharaoh'
import { memo, useCallback, type ReactNode } from 'react'
import { useNavigateBack } from '../../lib/hooks/use-navigate-back'
import { useWindowLocationHash } from '../../lib/use-window-location-hash'
import { Page } from './page'

export type MenuPageProps = {
  /**
   * Always displayed
   */
  menuTitle: ReactNode
  /**
   * Displayed when the menu is open (always open on desktop)
   */
  menuBody: ReactNode
  /**
   * Content of the page
   */
  pageContent: ReactNode
  /**
   * Optional borders to apply to the menu
   */
  border?: string
}

export const MenuPage = memo<MenuPageProps>(function MenuPage({
  menuTitle,
  menuBody,
  pageContent,
  border,
}: MenuPageProps) {
  const hash = useWindowLocationHash()
  const open = hash === 'menu'

  return (
    <Page>
      <MenuLayout
        menuTitle={menuTitle}
        openMenuButton={<OpenMenuButton />}
        closeMenuButton={<CloseMenuButton />}
        menuBody={menuBody}
        pageContent={pageContent}
        menuOpen={open}
        border={border}
      />
    </Page>
  )
})

const OpenMenuButton = memo(function OpenMenuButton() {
  const handleClick = useCallback(() => {
    window.location.hash = 'menu'
  }, [])

  return <Button onClick={handleClick} size="large" variant="text" avatar={<Avatar icon="bars-solid" size="40" />} />
})

const CloseMenuButton = memo(function CloseMenuButton() {
  const navigateBack = useNavigateBack()

  return <Button onClick={navigateBack} size="large" variant="text" avatar={<Avatar icon="xmark-solid" size="40" />} />
})
